import React, { useState, useEffect, useRef, useCallback, useMemo } from 'react';
import ReactGA from 'react-ga4';

// eslint-disable-next-line no-unused-vars
import { BrowserRouter as Router, Route, Routes, useNavigate, useLocation, Navigate } from 'react-router-dom';
import { Card, CardContent, CardHeader, CardTitle } from './components/ui/Card';
import { Button } from './components/ui/Button';
import { fetchSheetData } from './utils/fetchSheetData';
import Navigation from './components/ui/Navigation';
import Footer from './components/Footer';
import BackToTopButton from './components/BackToTopButton';
import QuantityControl from './components/QuantityControl';
import QuantityControlSansRemove from './components/QuantityControlSansRemove';
import Success from './components/Success';
import StickyIngredientList from './components/StickyIngredientList';
import './styles.css';
import './animations.css';
import './materialSymbols.css';
import '@fontsource/material-symbols-outlined';






const useSessionStorage = (key, defaultValue) => {
  const [state, setState] = useState(() => {
    const storedValue = sessionStorage.getItem(key);
    return storedValue !== null ? JSON.parse(storedValue) : defaultValue;
  });

  useEffect(() => {
    sessionStorage.setItem(key, JSON.stringify(state));
  }, [key, state]);

  return [state, setState];
};

const TeaSelectorApp = () => {
  // Initialize Google Analytics
  useEffect(() => {
    ReactGA.initialize('G-P6SEC5XZVE');
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });
  }, []);

  const [appState, setAppState] = useSessionStorage('teaAppState', {
    ingredients: [],
    selectedIngredients: [],
    signatureBlends: [],
    selectedSignatureBlend: null,
    summary: { totalCost: 0, benefits: '', quantity: 1, baseCost: 40 },
    cart: [],
    includeStrainer: false,
    strainerQuantity: 1,
    showPregnancyInfo: null,
    step: 1,
    dataFetched: false,
    filteredIngredients: []
  });

  const [popupMessage, setPopupMessage] = useState('');
  const [accordionOpen, setAccordionOpen] = useState(false);
  const [showPregnancyPopup, setShowPregnancyPopup] = useSessionStorage('showPregnancyPopup', true);
  const [isPregnancyPopupClosing, setIsPregnancyPopupClosing] = useState(false);

  const location = useLocation();
  const navigate = useNavigate();
  const accordionRef = useRef(null);

  const togglePregnancyInfo = useCallback(() => {
    setAppState(prevState => {
      const newShowPregnancyInfo = !prevState.showPregnancyInfo;
      let newSelectedIngredients;

      if (prevState.selectedSignatureBlend) {
        // If there's a selected signature blend, get its ingredients
        const blendIngredients = prevState.selectedSignatureBlend.ingredient_ids.map(id => 
          prevState.ingredients.find(i => i.id === id)
        );

        if (newShowPregnancyInfo) {
          // Filter out non-pregnancy safe ingredients
          newSelectedIngredients = blendIngredients.filter(ingredient => 
            ingredient.pregnancyInfo && ingredient.pregnancyInfo.trim() !== ''
          );
        } else {
          // Restore all original blend ingredients
          newSelectedIngredients = blendIngredients;
        }
      } else {
        // If it's a custom blend, filter or restore based on the new mode
        newSelectedIngredients = newShowPregnancyInfo
          ? prevState.selectedIngredients.filter(ingredient => 
              ingredient.pregnancyInfo && ingredient.pregnancyInfo.trim() !== ''
            )
          : prevState.ingredients.filter(ingredient => 
              prevState.selectedIngredients.some(selected => selected.id === ingredient.id)
            );
      }

      const newFilteredIngredients = newShowPregnancyInfo
        ? prevState.ingredients.filter(ingredient => ingredient.pregnancyInfo && ingredient.pregnancyInfo.trim() !== '')
        : prevState.ingredients;

      return {
        ...prevState,
        showPregnancyInfo: newShowPregnancyInfo,
        selectedIngredients: newSelectedIngredients,
        filteredIngredients: newFilteredIngredients,
      };
    });
  }, [setAppState]);
  
  const renderPregnancyToggle = useCallback(() => (
    <div className="pregnancy-toggle flex items-center justify-end">
      <span className="mr-2 text-sm">Show Pregnancy Info:</span>
      <label className="switch">
        <input
          type="checkbox"
          checked={appState.showPregnancyInfo}
          onChange={togglePregnancyInfo}
        />
        <span className="slider round"></span>
      </label>
    </div>
  ), [appState.showPregnancyInfo, togglePregnancyInfo]);

  useEffect(() => {
    if (location.pathname === '/step2' && appState.showPregnancyInfo === null && showPregnancyPopup) {
      setShowPregnancyPopup(true);
    }
  }, [location.pathname, appState.showPregnancyInfo, showPregnancyPopup, setShowPregnancyPopup]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await fetchSheetData();
        setAppState(prevState => ({
          ...prevState,
          ingredients: data.ingredients,
          signatureBlends: data.signatureBlends,
          dataFetched: true,
          filteredIngredients: data.ingredients
        }));
      } catch (error) {
        console.error('Error fetching sheet data:', error);
      }
    };

    if (!appState.dataFetched) {
      fetchData();
    }
  }, [appState.dataFetched, setAppState]);

  useEffect(() => {
    if (appState.showPregnancyInfo !== null) {
      setAppState(prevState => {
        const newFilteredIngredients = prevState.showPregnancyInfo
          ? prevState.ingredients.filter(ingredient => ingredient.pregnancyInfo && ingredient.pregnancyInfo.trim() !== '')
          : prevState.ingredients;

        let newSelectedIngredients;

        if (prevState.selectedSignatureBlend) {
          const blendIngredients = prevState.selectedSignatureBlend.ingredient_ids.map(id => 
            prevState.ingredients.find(i => i.id === id)
          );
          newSelectedIngredients = prevState.showPregnancyInfo
            ? blendIngredients.filter(ingredient => ingredient.pregnancyInfo && ingredient.pregnancyInfo.trim() !== '')
            : blendIngredients;
        } else {
          newSelectedIngredients = prevState.showPregnancyInfo
            ? prevState.selectedIngredients.filter(ingredient => ingredient.pregnancyInfo && ingredient.pregnancyInfo.trim() !== '')
            : prevState.selectedIngredients;
        }

        if (
          JSON.stringify(newFilteredIngredients) !== JSON.stringify(prevState.filteredIngredients) ||
          JSON.stringify(newSelectedIngredients) !== JSON.stringify(prevState.selectedIngredients)
        ) {
          return {
            ...prevState,
            filteredIngredients: newFilteredIngredients,
            selectedIngredients: newSelectedIngredients,
          };
        }
        return prevState;
      });
    }
  }, [appState.showPregnancyInfo, appState.ingredients, appState.selectedSignatureBlend, setAppState]);

  // = useCallback(() => {
   // const teaCost = appState.summary.baseCost * appState.summary.quantity;
   // const totalCost = teaCost;
   // const benefits = appState.selectedIngredients.map(i => i.benefits).join('. ');
   // return { totalCost, benefits };
 // }, [appState.summary.baseCost, appState.summary.quantity, appState.selectedIngredients]);


  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location.pathname]);

  const updateAppState = useCallback((newState) => {
    setAppState(prevState => ({ ...prevState, ...newState }));
  }, [setAppState]);



  const handlePregnancyResponse = useCallback((response) => {
    setIsPregnancyPopupClosing(true);
    setAppState(prevState => {
      const newFilteredIngredients = response
        ? prevState.ingredients.filter(ingredient => ingredient.pregnancyInfo && ingredient.pregnancyInfo.trim() !== '')
        : prevState.ingredients;

      const newSelectedIngredients = response
        ? prevState.selectedIngredients.filter(ingredient => ingredient.pregnancyInfo && ingredient.pregnancyInfo.trim() !== '')
        : prevState.selectedIngredients;

      return {
        ...prevState,
        showPregnancyInfo: response,
        filteredIngredients: newFilteredIngredients,
        selectedIngredients: newSelectedIngredients,
      };
    });

    setShowPregnancyPopup(false);

    setTimeout(() => {
      setIsPregnancyPopupClosing(false);
    }, 500);
  }, [setAppState, setShowPregnancyPopup]);

  const toggleIngredient = useCallback((ingredient) => {
    setAppState(prevState => {
      const isIngredientSelected = prevState.selectedIngredients.find(i => i.id === ingredient.id);
      let updatedIngredients;

      if (isIngredientSelected) {
        updatedIngredients = prevState.selectedIngredients.filter(i => i.id !== ingredient.id);
      } else if (prevState.selectedIngredients.length < 4) {
        updatedIngredients = [...prevState.selectedIngredients, ingredient];
      } else {
        setPopupMessage("You can only select up to 4 herbs. Please remove one first.");
        setTimeout(() => setPopupMessage(''), 3000);
        return prevState;
      }

      return {
        ...prevState,
        selectedIngredients: updatedIngredients
      };
    });
  }, [setAppState, setPopupMessage]);

  const selectSignatureBlend = (blend) => {
    const blendIngredients = blend.ingredient_ids.map(id => appState.ingredients.find(i => i.id === id));
    updateAppState({
      selectedSignatureBlend: blend,
      selectedIngredients: blendIngredients,
      step: 2
    });
    navigate('/step2');
    ReactGA.event({
      category: 'Card Interaction',
      action: 'Click',
      label: `Blend Support Card - ${blend.blend_name}`
    });
  };

  const addToCartAndProceed = useCallback(() => {
    if (appState.selectedIngredients.length === 0) {
      setPopupMessage('Please select at least one ingredient before adding to cart.');
      setTimeout(() => setPopupMessage(''), 3000);
      return;
    }

    const newItem = {
      id: Date.now(),
      blend: appState.selectedSignatureBlend ? appState.selectedSignatureBlend.blend_name : 'Custom Blend',
      ingredients: appState.selectedIngredients.map(i => i.name).join(', '),
      quantity: appState.summary.quantity,
      price: appState.summary.baseCost,
    };
    updateAppState({ 
      cart: [...appState.cart, newItem],
      step: 3
    });
    setPopupMessage('Added to cart!');
    setTimeout(() => setPopupMessage(''), 3000);
    navigate('/cart');
    ReactGA.event({
      category: 'Cart',
      action: 'Add to Cart',
      label: newItem.blend,
      value: newItem.price
    });
  }, [appState.selectedIngredients, appState.selectedSignatureBlend, appState.summary, appState.cart, updateAppState, setPopupMessage, navigate]);

  const removeFromCart = (id) => {
    updateAppState({ cart: appState.cart.filter(item => item.id !== id) });
  };

  const updateCartItemQuantity = (id, newQuantity) => {
    updateAppState({
      cart: appState.cart.map(item => 
        item.id === id 
          ? {
              ...item, 
              quantity: Math.max(1, newQuantity),
            } 
          : item
      )
    });
  };

  const calculateTotalCost = () => {
    const teaCost = appState.cart.reduce((total, item) => total + (item.price * item.quantity), 0);
    const strainerCost = appState.includeStrainer ? appState.strainerQuantity * 5 : 0;
    return teaCost + strainerCost;
  };

  const addAnotherBlend = () => {
    updateAppState({
      selectedIngredients: [],
      selectedSignatureBlend: null,
      step: 2
    });
    navigate('/step2');
  };

  const handleCheckout = async () => {
    try {
      const response = await fetch('/api/create-checkout-session', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ 
          cart: appState.cart, 
          includeStrainer: appState.includeStrainer, 
          strainerQuantity: appState.strainerQuantity,
          pregnancyActive: appState.showPregnancyInfo 
        }),
      });
  
      if (!response.ok) {
        const errorDetails = await response.text();
        throw new Error(`HTTP error! status: ${response.status}, message: ${errorDetails}`);
      }
  
      const session = await response.json();
      window.location.href = session.url;
    } catch (error) {
      console.error('Error:', error);
      setPopupMessage(`There was an error processing your order. Please try again. Error: ${error.message}`);
      setTimeout(() => setPopupMessage(''), 5000);
    }
    ReactGA.event({
      category: 'Cart',
      action: 'Checkout',
      label: 'Initiate Checkout',
      value: appState.cart.reduce((total, item) => total + (item.price * item.quantity), 0)
    });
  };

  const renderBackButton = useCallback((onClick, text) => (
    <button onClick={onClick} className="back-button">
      <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">
        <line x1="19" y1="12" x2="5" y2="12"></line>
        <polyline points="12 19 5 12 12 5"></polyline>
      </svg>
      {text}
    </button>
  ), []);

  const renderIngredientCard = useCallback((ingredient, isSelected) => (
    <Card
      key={ingredient.id}
      className={`accordion-card cursor-pointer transition-all hover:shadow-lg ${isSelected ? 'active' : ''} fade-in hover-fade`}
      onClick={() => {
        ReactGA.event({
          category: 'Card Interaction',
          action: 'Click',
          label: `Accordion Card - ${ingredient.name}`
        });
        toggleIngredient(ingredient);
      }}
    >
      <CardHeader>
        <CardTitle className="font-title text-secondary">{ingredient.name}</CardTitle>
      </CardHeader>
      <CardContent>
        <p className="text-secondary">{ingredient.benefits}</p>
        {ingredient.flavourProfile && (
          <p className="mt-2 text-secondary"><strong>Flavour Profile:</strong> {ingredient.flavourProfile}</p>
        )}
        <div className="mt-2">
          {ingredient.tags.map((tag, index) => (
            <span key={index} className="ingredient-tag">{tag}</span>
          ))}
        </div>
       
        {appState.showPregnancyInfo && ingredient.pregnancyInfo && (
          <div className="pregnancy-info mt-3">
            <div className="pregnancy-icon">
            <svg fill="#000000" width="800px" height="800px" viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg">
              <title/>
              <g id="Outline">
                <path d="M10.19,41.61c3.06,8.53,11.68,14.7,21.81,14.7s18.75-6.17,21.81-14.7c2.87-.1,5.19-2.82,5.19-6.15,0-3.15-2.08-5.76-4.74-6.11C51.68,20.09,42.68,13.24,32,13.24S12.32,20.09,9.74,29.35C7.08,29.7,5,32.31,5,35.46,5,38.79,7.32,41.51,10.19,41.61ZM57,35.46a4.06,4.06,0,0,1-2.56,4,20.08,20.08,0,0,0,.3-7.93A4.19,4.19,0,0,1,57,35.46ZM28.51,15.53c-1.91,1.9-3.31,4.69-1.77,8a1,1,0,0,0,.91.58,1,1,0,0,0,.9-1.41c-1.91-4.17,2.63-6.92,3.68-7.49.39,0,.77,0,1.16.06-1.14,1.94-1.88,4.53,0,6.18A1,1,0,0,0,34.72,20c-1.21-1,.22-3.42.91-4.42C45.48,17.16,53,25.16,53,34.78c0,10.77-9.42,19.53-21,19.53S11,45.55,11,34.78C11,25.11,18.59,17.09,28.51,15.53Zm-19.25,16a20.08,20.08,0,0,0,.3,7.93,4.06,4.06,0,0,1-2.56-4A4.19,4.19,0,0,1,9.26,31.55Z"/>
                <path d="M20.68,34.75a1,1,0,0,0,1-1,1.87,1.87,0,0,1,1.61-2.05,1.87,1.87,0,0,1,1.61,2.05,1,1,0,0,0,2,0,3.86,3.86,0,0,0-3.61-4.05,3.86,3.86,0,0,0-3.61,4.05A1,1,0,0,0,20.68,34.75Z"/>
                <path d="M37.23,34.75a1,1,0,0,0,1-1,1.87,1.87,0,0,1,1.61-2.05,1.87,1.87,0,0,1,1.61,2.05,1,1,0,0,0,2,0,3.86,3.86,0,0,0-3.61-4.05,3.86,3.86,0,0,0-3.61,4.05A1,1,0,0,0,37.23,34.75Z"/>
                <path d="M32,47.38A5.36,5.36,0,0,0,37.35,42a1,1,0,0,0-2,0,3.35,3.35,0,1,1-6.7,0,1,1,0,0,0-2,0A5.36,5.36,0,0,0,32,47.38Z"/>
              </g>
            </svg>
            </div>
            <p className="pregnancy-text">{ingredient.pregnancyInfo}</p>
          </div>
        )}
      </CardContent>
      <div className={`label ${isSelected ? 'remove' : 'add'}`}>
        {isSelected ? '- Remove' : '+ Add'}
      </div>
    </Card>
  ), [toggleIngredient, appState.showPregnancyInfo]);

  const ingredientsToDisplay = useMemo(() => {
    return appState.showPregnancyInfo
      ? appState.filteredIngredients
      : appState.ingredients;
  }, [appState.showPregnancyInfo, appState.filteredIngredients, appState.ingredients]);


  const toggleAccordion = useCallback(() => {
    setAccordionOpen(prevOpen => !prevOpen);
    if (!accordionOpen && accordionRef.current) {
      setTimeout(() => {
        accordionRef.current.scrollIntoView({ behavior: 'smooth' });
      }, 100);
    }
  }, [accordionOpen]);

  const renderIcon = (iconName, isActive = false) => {
    return (
      <span 
        className="material-symbols-outlined mr-4"
        style={{ 
          fontSize: '48px', 
          color: isActive ? '#ffffff' : '#2B4C3B',
        }}
      >
        {iconName}
      </span>
    );
  };

  const renderStep1 = () => (
    <div className="step fade-in">
      <div className="intro-section fade-in-bottom">
        <div className="intro-content">
          <div className="intro-text">
            <h1 className="intro-title">Custom Tea Blends</h1>
            <p>
            Crafted in Melbourne by a qualified naturopath using premium organic herbs, each blend is hand-selected and carefully combined to deliver optimal therapeutic benefits and a harmonious taste. Elevate your wellness journey with our custom-blended teas.
            </p>
          </div>
          <img 
            src="https://images.squarespace-cdn.com/content/v1/653f71cf071819032b675d2d/93e1f244-e1bb-4923-99ac-3f1b1577650d/good-tea-pic.png?format=2500w" 
            alt="Custom Tea Blend Container" 
            className="intro-image"
          />
        </div>
      </div>
  
      <div className="container mx-auto px-4 fade-in-bottom">
        <h2 className="text-4xl font-title mb-6 text-secondary">Start creating your custom blend</h2>
        <Card
          className="custom-blend-card cursor-pointer start-from-scratch"
          onClick={() => {
            updateAppState({
              selectedSignatureBlend: null,
              selectedIngredients: [],
              step: 2
            });
            navigate('/step2');
          }}
        >
          <CardHeader>
            <CardTitle className="font-title text-secondary">Start from Scratch</CardTitle>
          </CardHeader>
          <CardContent>
            <p className="text-secondary">Create your own loose leaf blend by selecting herbs that appeal to you.</p>
          </CardContent>
        </Card>
        <h2 className="text-4xl font-title mb-6 text-secondary">Or, select an area you're looking to support</h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 fade-in-list support">
        {appState.signatureBlends.map(blend => {
  const isActive = appState.selectedSignatureBlend && appState.selectedSignatureBlend.id === blend.id;
  return (
    <Card
      key={blend.id}
      className={`blend-support-card cursor-pointer hover-fade ${isActive ? 'active-2' : ''}`}
      onClick={() => {
        ReactGA.event({
          category: 'Card Interaction',
          action: 'Click',
          label: `Blend Support Card - ${blend.blend_name}`
        });
        selectSignatureBlend(blend);
      }}
    >
      <CardHeader className="flex items-center">
        {renderIcon(blend.icon_name, isActive)}
        <CardTitle className="font-title text-secondary">{blend.blend_name}</CardTitle>
      </CardHeader>
      <CardContent>
        <p className="text-secondary">{blend.blend_desc}</p>
      </CardContent>
    </Card>
  );
})}
        </div>
      </div>
    </div>
  );

  const renderStep2 = useCallback(() => {
    const stepTitle = appState.selectedSignatureBlend
      ? `Step 2: Your ${appState.selectedSignatureBlend.blend_name} Focused Tea Blend`
      : "Step 2: Your Tea Blend";

    return (
        <div className="step fade-in">
          {(showPregnancyPopup || isPregnancyPopupClosing) && (
          <div className={`pregnancy-popup ${isPregnancyPopupClosing ? 'fade-out' : 'fade-in'}`}>
            <div className={`pregnancy-popup-content ${isPregnancyPopupClosing ? 'fade-out-bottom' : 'fade-in-bottom'}`}>
              <h2 className="text-2xl font-title mb-4 text-secondary">Pregnancy Information</h2>
              <p className="mb-6 text-secondary">If you select 'yes' you'll see extra information about safety during pregnancy</p>
              <div className="pregnancy-popup-buttons">
                <Button onClick={() => handlePregnancyResponse(true)} className="hover-fade mr-4">Yes</Button>
                <Button onClick={() => handlePregnancyResponse(false)} className="hover-fade">No</Button>
              </div>
            </div>
          </div>
        )}
            <div className="flex justify-between items-center mb-4">
          {renderBackButton(() => navigate('/'), "Back to Blends")}
        </div>
        <div className="summary-container bg-white mb-6 rounded-lg shadow-md fade-in-bottom">
          <h2 className="text-4xl font-title mb-6 text-secondary">{stepTitle}</h2>

          <p className="mb-2 text-secondary">
            {appState.selectedSignatureBlend 
              ? "Here are the ingredients for your selected blend. You can modify the blend by adding or removing herbs."
              : "Create your custom blend by selecting up to 4 herbs from the list below."}
          </p>
          <div className="p-tog mb-2 text-secondary">{renderPregnancyToggle()}</div>
          <p className="mb-4 text-secondary font-bold">
            Selected ingredients: <span className="font-extrabold">{appState.selectedIngredients.length}/4</span>
          </p>
        
          <div className="active-ingredients fade-in-list">
          {appState.selectedIngredients.map(ingredient => renderIngredientCard(ingredient, true))}
          
          {appState.selectedIngredients.length < 4 && (
            <Card
              className="create-blend-card cursor-pointer add-ingredients"
              onClick={toggleAccordion}
            >
              <CardHeader>
                <CardTitle className="font-title text-secondary">Add Herbs</CardTitle>
              </CardHeader>
              <CardContent>
                <p className="text-secondary">Select herbs from the list below to create your blend.</p>
              </CardContent>
            </Card>
            )}
          </div>
    
          {/* Desktop buttons */}
          <div className="desktop-button-container mt-4">
            <Button 
              onClick={addToCartAndProceed} 
              className={`add-to-cart-button responsive-button ${appState.selectedIngredients.length === 0 ? 'opacity-50 cursor-not-allowed' : ''}`}
              disabled={appState.selectedIngredients.length === 0}
            >
              Add to Cart
            </Button>
            {appState.cart.length > 0 && (
              <Button 
                onClick={() => navigate('/cart')} 
                className="view-order-button responsive-button ml-4"
              >
                View Order
              </Button>
            )}
          </div>
          

          <div className="accordion mt-4" ref={accordionRef}>
          <div className="accordion-header" onClick={toggleAccordion}>
            Modify your blend
            <span>{accordionOpen ? '-' : '+'}</span>
          </div>
          <div className={`accordion-content ${accordionOpen ? 'open' : ''}`}>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              {ingredientsToDisplay
                .filter(ingredient => !appState.selectedIngredients.find(i => i.id === ingredient.id))
                .map(ingredient => renderIngredientCard(ingredient, false))}
              </div>
            </div>
          </div>
        </div>
        
        {/* Mobile sticky buttons */}
        <div className="sticky-buttons-container">
          {appState.cart.length > 0 ? (
            <>
              <Button 
                onClick={addToCartAndProceed} 
                className={`sticky-button add-to-cart-button ${appState.selectedIngredients.length === 0 ? 'opacity-50 cursor-not-allowed' : ''}`}
                disabled={appState.selectedIngredients.length === 0}
              >
                Add to Cart
              </Button>
              <Button 
                onClick={() => navigate('/cart')} 
                className="sticky-button view-order-button"
              >
                View Order
              </Button>
            </>
          ) : (
            <Button 
              onClick={addToCartAndProceed} 
              className={`sticky-button add-to-cart-button ${appState.selectedIngredients.length === 0 ? 'opacity-50 cursor-not-allowed' : ''}`}
              disabled={appState.selectedIngredients.length === 0}
            >
              Add to Cart
            </Button>
          )}
        </div>
        <StickyIngredientList selectedIngredients={appState.selectedIngredients} />

      </div>
    );
  }, [
    showPregnancyPopup, 
    isPregnancyPopupClosing, 
    handlePregnancyResponse, 
    renderBackButton, 
    navigate, 
    appState.selectedSignatureBlend, 
    appState.selectedIngredients, 
    appState.cart, 
    renderIngredientCard, 
    toggleAccordion, 
    addToCartAndProceed, 
    accordionOpen, 
    ingredientsToDisplay,
    renderPregnancyToggle
  ]);

  const renderStep3 = () => (
    <div className="step fade-in">
      {renderBackButton(() => navigate('/step2'), "Back to Your Tea")}
      
      <div className="desktop-layout">
        <div className="main-content">
          <div className="summary-container bg-white mb-6 rounded-lg shadow-md fade-in-bottom">
            <h2 className="text-4xl font-title mb-6 text-secondary">Step 3: Review Your Order</h2>
        
            <h2 className="text-3xl font-title mb-4 text-secondary">Order Details</h2>
            <div className="mb-4 text-secondary">
              <p className="font-body">Each order contains 50g of tea, which is approximately 50 serves.</p>
              <p className="mb-4 text-secondary font-bold">Free shipping within Australia</p>
            </div>
        
            {appState.cart.length === 0 ? (
              <p>Your cart is empty. Add a blend to your cart to proceed.</p>
            ) : (
              <div className="cart-items fade-in-list">
                {appState.cart.map((item) => (
                  <Card key={item.id} className="cart-item mb-4 p-4 rounded-lg shadow-sm hover-fade">
                    <CardHeader>
                      <CardTitle className="text-xl font-title text-secondary">{item.blend}</CardTitle>
                    </CardHeader>
                    <CardContent>
                      <div className="flex justify-between items-center">
                        <p className="font-body mb-2"><strong>Ingredients:</strong> {item.ingredients}</p>
                      </div>
                      <QuantityControl
                        quantity={item.quantity}
                        onIncrease={() => updateCartItemQuantity(item.id, item.quantity + 1)}
                        onDecrease={() => updateCartItemQuantity(item.id, Math.max(1, item.quantity - 1))}
                        onRemove={() => removeFromCart(item.id)}
                      />
                      <p className="font-body mt-2"><strong>Price:</strong> ${(item.price * item.quantity).toFixed(2)}</p>
                    </CardContent>
                  </Card>
                ))}
        
                <div className="mt-4 p-4 border-t">
                  <h3 className="text-2xl font-title mb-4 text-secondary">Tea Infuser (Optional)</h3>
                  <label className="flex items-center mb-2">
                    <input
                      type="checkbox"
                      checked={appState.includeStrainer}
                      onChange={(e) => updateAppState({ includeStrainer: e.target.checked })}
                      className="mr-2"
                    />
                    <span className="font-body">Add a tea strainer to your order?</span>
                  </label>
                  {appState.includeStrainer && (
                    <div className="ml-6 mt-2 flex items-center justify-between tsremove">
                      <QuantityControlSansRemove className="tsremove"
                        quantity={appState.strainerQuantity}
                        onIncrease={() => updateAppState({ strainerQuantity: appState.strainerQuantity + 1 })}
                        onDecrease={() => updateAppState({ strainerQuantity: Math.max(1, appState.strainerQuantity - 1) })}
                      />
                      <div className='textspan'>
                        <p className="font-body mt-2"><strong>Total:</strong> ${(appState.strainerQuantity * 5).toFixed(2)}</p>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}
            <Card
              className="add-another-blend-card cursor-pointer mt-6"
              onClick={addAnotherBlend}
            >
              <CardHeader>
                <CardTitle className="font-title text-secondary">Add Another Blend to Your Order</CardTitle>
              </CardHeader>
              <CardContent>
                <p className="text-secondary">Create a new custom blend or choose from our signature blends to add variety to your order.</p>
              </CardContent>
            </Card>
          </div>
        </div>

        <div className="order-total-card">
          <div className="max-w-lg mx-auto">
            <h2 className="text-3xl font-title mb-6 text-secondary">Order Total</h2>
            <div className="space-y-4">
              <p className="font-body text-lg">
                <strong>Total Cost:</strong> ${calculateTotalCost().toFixed(2)}
              </p>
              <p className="font-body text-sm text-gray-600">
                You can apply a promo code during the checkout process if you have one.
              </p>
              <Button
                onClick={handleCheckout}
                className="checkout-button w-full py-5 text-xl bg-secondary text-white hover:opacity-90 mt-6"
                disabled={appState.cart.length === 0}
              >
                Checkout and Pay
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <div className="bg-white min-h-screen">
      <Navigation />
      {popupMessage && (
        <div className="popup-message">
          {popupMessage}
        </div>
      )}
      <div className="container mx-auto p-8">
        <Routes>
          <Route path="/" element={renderStep1()} />
          <Route path="/step2" element={renderStep2()} />
          <Route path="/cart" element={renderStep3()} />
          <Route path="/success" element={<Success appState={appState} updateAppState={updateAppState} />} />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </div>
      <Footer /> 
      <BackToTopButton />
    </div>
  );
};

export default TeaSelectorApp;